export default {
  CREATE_CONVERSATION: (state, payload) => {
    state.currentConversation = payload._id;
  },

  HANDLE_ANALYTICS_MESSAGE: (state, payload) => {
    state.currentConversation = payload._id.conversation;
    state.lastAnalysis = payload._id.analysis;
  },

  SET_METRICS: (state, payload) => {
    state.availableMetrics = payload;
  },

  SET_CONVERSATION: (state, payload) => {
    if (payload._id && payload._id._id) {
      state.currentConversation = payload._id;
    }
  },
};
