let getters = {
  ARTICLES: (state) => {
    return state.articles;
  },
  ARTICLESPAGINATED: (state) => {
    return state.articlesPaginated;
  },
  ARTICLESPAGINATEDTOTAL: (state) => {
    return state.articlesPaginatedTotal;
  },
  PROVIDERS: (state) => {
    return state.providers;
  },
  WAREHOUSES: (state) => {
    return state.warehouses.filter((singleWarehouse) => {
      return singleWarehouse.STATUS === "AC";
    });
  },
  WAREHOUSES_ALL: (state) => {
    return state.warehouses;
  },
  PURCHASEORDERS: (state) => {
    return state.purchaseOrders;
  },
  PAYMENTPERIODS: (state) => {
    return state.paymentPeriods;
  },
  REQUESTORDERS: (state) => {
    return state.requestOrders;
  },
  TYPESDELIVERY: (state) => {
    return state.typesDelivery;
  },
  LASTPURCHASEORDERNUMBER: (state) => {
    return state.lastPurchaseOrderNumber;
  },
  LASTWAREHOUSETRANSFERNUMBER: (state) => {
    return state.lastWarehouseTransferNumber;
  },
  SATARTICLESDIVISION: (state) => {
    return state.satArticlesDivision;
  },
  MATERIALRECEPTIONS: (state) => {
    return state.materialReceptions;
  },
};

export default getters;
