let mutations = {
  RESTORE_PURCHASES_STATES: (state) => {
    Object.assign(state, {
      articles: [],
      providers: [],
      warehouses: [],
      purchaseOrders: [],
      requestOrders: [],
      paymentPeriods: [],
      typesDelivery: [],
      lastPurchaseOrderNumber: 1,
      lastWarehouseTransferNumber: 1,
      satArticlesDivision: [],
    });
  },
  SAVE_ARTICLE: (state, payload) => {
    const currentArticles = [...state.articles];
    currentArticles.push(payload._id);
    state.articles = currentArticles;
  },
  SET_ARTICLES: (state, payload) => {
    state.articles = payload;
  },
  SET_ARTICLES_PAGINATED: (state, payload) => {
    state.articlesPaginated = payload.articles;
    state.articlesPaginatedTotal = payload.total;
  },
  EDIT_ARTICLE: (state, payload) => {
    const currentArticles = [...state.articles];
    for (const [
      singleArticleIndex,
      singleArticle,
    ] of currentArticles.entries()) {
      if (singleArticle._id === payload._id._id) {
        currentArticles[singleArticleIndex] = payload._id;
      }
    }
    state.articles = currentArticles;
  },
  SAVE_PROVIDER: (state, payload) => {
    const currentProviders = [...state.providers];
    currentProviders.push(payload._id);
    state.providers = currentProviders;
  },
  SET_PROVIDERS: (state, payload) => {
    state.providers = payload;
  },
  EDIT_PROVIDER: (state, payload) => {
    const currentProviders = [...state.providers];
    for (const [
      singleProviderIndex,
      singleProvider,
    ] of currentProviders.entries()) {
      if (singleProvider._id === payload._id._id) {
        currentProviders[singleProviderIndex] = payload._id;
      }
    }
    state.providers = currentProviders;
  },
  SAVE_WAREHOUSE: (state, payload) => {
    const currentWarehouses = [...state.warehouses];
    const currentUsers = [...payload.context.rootState.Global.users];
    const currentUser = payload.context.rootState.Main.user;
    currentWarehouses.push(payload._id);

    for (const [singleUserIndex, singleUser] of currentUsers.entries()) {
      currentUsers[singleUserIndex].ALMACENES.push(payload._id._id);
    }
    currentUser.ALMACENES.push(payload._id._id);

    payload.context.rootState.Global.users = currentUsers;
    payload.context.rootState.Main.use = currentUser;
    state.warehouses = currentWarehouses;
  },
  ACTIVATE_WAREHOUSE: (state, payload) => {
    const currentWarehouses = [...state.warehouses];
    for (const [
      singleWarehouseIndex,
      singleWarehouse,
    ] of currentWarehouses.entries()) {
      if (singleWarehouse._id === payload._id) {
        currentWarehouses[singleWarehouseIndex].STATUS = "AC";
      }
    }
    state.warehouses = currentWarehouses;
  },
  DISABLE_WAREHOUSE: (state, payload) => {
    const currentWarehouses = [...state.warehouses];
    for (const [
      singleWarehouseIndex,
      singleWarehouse,
    ] of currentWarehouses.entries()) {
      if (singleWarehouse._id === payload._id) {
        currentWarehouses[singleWarehouseIndex].STATUS = "PE";
      }
    }
    state.warehouses = currentWarehouses;
  },
  SET_WAREHOUSES: (state, payload) => {
    state.warehouses = payload;
  },
  EDIT_WAREHOUSE: (state, payload) => {
    const currentWarehouses = [...state.warehouses];
    for (const [
      singleWarehouseIndex,
      singleWarehouse,
    ] of currentWarehouses.entries()) {
      if (singleWarehouse._id === payload._id._id) {
        currentWarehouses[singleWarehouseIndex] = payload._id;
      }
    }
    state.warehouses = currentWarehouses;
  },
  SET_PROVIDERS_PRICES: (state, payload) => {
    let finalArticles = [...state.articles];
    for (let singlePrice of payload) {
      let singlePriceWithProps = { ...singlePrice };
      if (singlePriceWithProps.CAMBIOS) {
        singlePriceWithProps.CAMBIOS.push({
          COSTO: singlePriceWithProps.COSTO,
          FECHA: new Date(),
        });
      } else {
        singlePriceWithProps.CAMBIOS = [
          { COSTO: singlePriceWithProps.COSTO, FECHA: new Date() },
        ];
      }
      for (const [
        singleArticleKey,
        singleArticle,
      ] of state.articles.entries()) {
        let found = false;
        if (singlePrice.CLAVE_ART === singleArticle._id) {
          for (const [
            singleProviderKey,
            singleProviderPrice,
          ] of singleArticle.PRECIOS_PROV.entries()) {
            if (
              singleProviderPrice.CLAVE_ART === singlePrice.CLAVE_ART &&
              singleProviderPrice.CLAVE_PRV === singlePrice.CLAVE_PRV
            ) {
              finalArticles[singleArticleKey].PRECIOS_PROV[singleProviderKey] =
                singlePrice;
              found = true;
              break;
            }
          }
          if (!found) {
            finalArticles[singleArticleKey].PRECIOS_PROV.push(singlePrice);
            break;
          }
        }
      }
    }
    state.articles = finalArticles;
  },
  ADD_REQUEST_ORDERS: (state, payload) => {
    for (const singleRequestOrder of payload) {
      state.requestOrders.push(singleRequestOrder);
    }
    state.lastPurchaseOrderNumber = payload[payload.length - 1].CLAVE_PED + 1;
  },
  ADD_PURCHASE_ORDERS: (state, payload) => {
    state.purchaseOrders.push(payload);
  },
  SET_PURCHASE_ORDERS: (state, payload) => {
    state.purchaseOrders = payload;
  },
  SET_REQUEST_ORDERS: (state, payload) => {
    state.requestOrders = payload;
  },
  CANCEL_PURCHASE_ORDER: (state, payload) => {
    // Get current purharse orders
    const currentPurchaseOrders = [...state.purchaseOrders];

    // Change the status of the purchase order to cancelled
    for (const [
      singlePurchaseOrderIndex,
      singlePurchaseOrder,
    ] of currentPurchaseOrders.entries()) {
      if (singlePurchaseOrder._id === payload) {
        currentPurchaseOrders[singlePurchaseOrderIndex].STATUS_PED = "CA";
      }
    }

    // Set purchse order to the ones changed
    state.purchaseOrders = currentPurchaseOrders;
  },
  EDIT_REQUEST_ORDER: (state, payload) => {
    const currentRequestOrders = [...state.requestOrders];
    const currentPurchaseOrders = [...state.purchaseOrders];

    for (const [
      singleRequestOrderIndex,
      singleRequestOrder,
    ] of currentRequestOrders.entries()) {
      if (singleRequestOrder._id === payload._id._id) {
        currentRequestOrders[singleRequestOrderIndex] = payload._id;
      }
    }

    for (const [
      singlePurchaseOrderIndex,
      singlePurchaseOrder,
    ] of currentPurchaseOrders.entries()) {
      if (singlePurchaseOrder._id === payload._id._id) {
        currentPurchaseOrders[singlePurchaseOrderIndex] = payload._id;
      }
    }

    state.requestOrders = currentRequestOrders;
    state.purchaseOrders = currentPurchaseOrders;
  },
  DELETE_REQUEST_ORDER: (state, payload) => {
    const currentRequestOrders = [...state.requestOrders];
    const finalRequestOrders = [];
    for (const singleRequestOrder of currentRequestOrders) {
      if (!payload._id.objectsDeleted.includes(singleRequestOrder._id)) {
        finalRequestOrders.push(singleRequestOrder);
      }
    }
    state.requestOrders = finalRequestOrders;
  },
  ADD_PAYMENT_PERIODS: (state, payload) => {
    const currentPaymentPeriods = [...state.paymentPeriods];
    currentPaymentPeriods.push(payload._id);
    state.paymentPeriods = currentPaymentPeriods;
  },
  SET_PAYMENT_PERIODS: (state, payload) => {
    state.paymentPeriods = payload;
  },
  ADD_TYPES_DELIVERY: (state, payload) => {
    const currentTypeDeliveries = [...state.typesDelivery];
    currentTypeDeliveries.push(payload._id);
    state.typesDelivery = currentTypeDeliveries;
  },
  SET_TYPES_DELIVERY: (state, payload) => {
    state.typesDelivery = payload;
  },
  SET_PROVIDER_PRICE: (state, payload) => {
    let finalArticles = [...state.articles];
    for (const [singleArticleKey, singleArticle] of finalArticles.entries()) {
      if (singleArticle._id === payload.CLAVE_ART) {
        for (const [
          singleProviderPriceIndex,
          singleProviderPrice,
        ] of singleArticle.PRECIOS_PROV.entries()) {
          if (singleProviderPrice.CLAVE_PRV === payload.CLAVE_PRV) {
            finalArticles[singleArticleKey].PRECIOS_PROV[
              singleProviderPriceIndex
            ].COSTO = payload.COSTO;
            finalArticles[singleArticleKey].PRECIOS_PROV[
              singleProviderPriceIndex
            ].COSTO_ANT = payload.COSTO_ANT;
            break;
          }
        }
      }
    }
    state.articles = finalArticles;
  },
  CONFIRM_REQUEST_ORDERS: (state, payload) => {
    try {
      const finalRequestOrders = [...state.requestOrders];
      const finalPurchaseOrders = [...state.purchaseOrders];
      const finalArticles = [...state.articles];
      const finalSales = [...payload.context.rootState.Sales.sales];

      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        for (const singleRequestOrderArticle of payload._id.purchaseOrder
          .PEDIDO_DET) {
          if (singleArticle._id === singleRequestOrderArticle.CLAVE_ART._id) {
            finalArticles[singleArticleIndex].FE_ULTCMP = new Date();
            break;
          }
        }
      }

      for (const [
        singleRequestOrderKey,
        singleRequestOrder,
      ] of state.requestOrders.entries()) {
        if (singleRequestOrder._id === payload._id.purchaseOrder._id) {
          finalRequestOrders.splice(singleRequestOrderKey, 1);
          break;
        }
      }

      if (payload._id.purchaseOrder.VENTA) {
        for (const [singleSaleIndex, singleSale] of finalSales.entries()) {
          if (singleSale._id.toString() === payload._id.purchaseOrder.VENTA) {
            finalSales[singleSaleIndex].ORDENES_COMPRA.push(
              payload._id.purchaseOrder
            );
          }
        }
      }

      finalPurchaseOrders.push(payload._id.purchaseOrder);
      state.requestOrders = finalRequestOrders;
      state.purchaseOrders = finalPurchaseOrders;
      state.articles = finalArticles;
      payload.context.rootState.Sales.sales = finalSales;
    } catch (error) {
      console.log(error);
    }
  },
  PRINT_OR_SEND_PURCHASE_ORDER: (state, payload) => {
    let finalRequestOrders = [...state.requestOrders];
    let finalPurchaseOrders = [...state.purchaseOrders];
    for (const [
      singleRequestOrderKey,
      singleRequestOrder,
    ] of state.requestOrders.entries()) {
      if (singleRequestOrder._id === payload._id) {
        singleRequestOrder.IMPRESO += 1;
      }
    }
    for (const [
      singlePurchaseOrderKey,
      singlePurchaseOrder,
    ] of state.requestOrders.entries()) {
      if (singlePurchaseOrder._id === payload._id) {
        singlePurchaseOrder.IMPRES += 1;
      }
    }
    state.requestOrders = finalRequestOrders;
    state.finalPurchaseOrders = finalPurchaseOrders;
  },
  SET_LAST_PURCHASE_ORDER_NUMBER: (state, payload) => {
    state.lastPurchaseOrderNumber = payload.lastPurchaseNumber;
  },
  SAVE_PROVIDERS_COSTS: (state, payload) => {
    try {
      let currentArticles = [...state.articles];

      // Move trought the articles
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of currentArticles.entries()) {
        // If article is the same as the one changed
        if (singleArticle._id === payload.CLAVE_ART) {
          for (const [
            singleArticleProviderIndex,
            singleArticleProvider,
          ] of singleArticle.PRECIOS_PROV.entries()) {
            // If article provider is the same as the one we changed
            if (singleArticleProvider._id === payload._id) {
              currentArticles[singleArticleIndex].PRECIOS_PROV[
                singleArticleProviderIndex
              ].COSTOS = payload.COSTOS;
            }
          }
        }
      }

      state.articles = currentArticles;
    } catch (error) {
      console.log(error);
    }
  },
  SAVE_PROVIDERS_DISCOUNTS: (state, payload) => {
    let currentArticles = [...state.articles];

    // Move through system articles
    for (const [
      singleArticleIndex,
      singleArticle,
    ] of currentArticles.entries()) {
      // If article is the same as the one sneded to server
      if (singleArticle._id === payload.CLAVE_ART) {
        for (const [
          singleArticleProviderIndex,
          singleArticleProvider,
        ] of singleArticle.PRECIOS_PROV.entries()) {
          // If provider price is the same as the one we change
          if (singleArticleProvider._id === payload._id) {
            currentArticles[singleArticleIndex].PRECIOS_PROV[
              singleArticleProviderIndex
            ].DESCS = payload.DESCS;
          }
        }
      }
    }
    state.article = currentArticles;
  },
  SAVE_ARTICLE_PRESENTATION: (state, payload) => {
    try {
      let finalArticles = [...state.articles];
      for (const [singleArticleKey, singleArticle] of finalArticles.entries()) {
        if (payload._id.CLAVE_ART === singleArticle._id) {
          // If PRESENTACIONES dont exit create it
          if (!finalArticles[singleArticleKey].PRESENTACIONES) {
            finalArticles[singleArticleKey].PRESENTACIONES = [payload._id];
          } else {
            // If PRESENTACIONES exist add one to the array
            finalArticles[singleArticleKey].PRESENTACIONES.push(payload._id);
          }
          break;
        }
      }
      state.articles = finalArticles;
    } catch (error) {
      console.log(error);
    }
  },
  SET_LAST_WAREHOUSE_TRANSFER_NUMBER: (state, payload) => {
    state.lastWarehouseTransferNumber = payload.lastWarehouseTransferNumber;
  },
  TRANSFER_ARTICLES: (state, payload) => {
    let finalArticles = [...state.articles];
    let finalWarehouses = [...state.warehouses];
    for (const singleArticleWarehouse of payload._id.articlesWarehousesSave) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
          if (singleArticle.CANT_ALMACENES) {
            let found = false;
            for (const [
              singleWarehouseQuantityIndex,
              singleWarehouseQuantity,
            ] of singleArticle.CANT_ALMACENES.entries()) {
              if (singleWarehouseQuantity._id === singleArticleWarehouse._id) {
                found = true;
                if (singleArticleWarehouse.CANTIDAD) {
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD += Number(singleArticleWarehouse.CANTIDAD);
                }

                if (singleArticleWarehouse.CANTIDAD_VIRTUAL) {
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD_VIRTUAL += Number(
                    singleArticleWarehouse.CANTIDAD_VIRTUAL
                  );
                }
              }
            }
            if (found === false) {
              finalArticles[singleArticleIndex].CANT_ALMACENES.push({
                ...singleArticleWarehouse,
              });
            }
          } else {
            finalArticles[singleArticleIndex].CANT_ALMACENES = [
              { ...singleArticleWarehouse },
            ];
          }
          break;
        }
      }
    }

    let allTransfersDone = [...payload._id.transfersDone];
    for (const [
      singleTrasferIndex,
      singleTransfer,
    ] of allTransfersDone.entries()) {
      for (const [
        singleWarehouseindex,
        singleWarehouse,
      ] of state.warehouses.entries()) {
        for (let currentWarehouse of state.warehouses) {
          if (currentWarehouse._id === singleTransfer.ORIGEN) {
            allTransfersDone[singleTrasferIndex].ORIGEN = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentWarehouse of state.warehouses) {
          if (currentWarehouse._id === singleTransfer.DESTINO) {
            allTransfersDone[singleTrasferIndex].DESTINO = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentArticle of state.articles) {
          if (currentArticle._id === singleTransfer.CLAVE_ART) {
            allTransfersDone[singleTrasferIndex].CLAVE_ART = {
              _id: currentArticle._id,
              CLAVE_ART: currentArticle.CLAVE_ART,
              DESCRIPCION: currentArticle.DESCRIPCION,
            };
            break;
          }
        }

        if (
          finalWarehouses[singleWarehouseindex]._id === singleTransfer.CLAVE_ALM
        ) {
          if (finalWarehouses[singleWarehouseindex].CLAVE_TRANS) {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS.push(
              allTransfersDone[singleTrasferIndex]
            );
          } else {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS = [
              allTransfersDone[singleTrasferIndex],
            ];
          }
        }
      }
    }

    state.articles = finalArticles;
    state.warehouses = finalWarehouses;
    state.lastWarehouseTransferNumber = payload._id.lastWarehouseTransferNumber;
  },
  CONFIRM_TRANSFER: (state, payload) => {
    let finalArticles = [...state.articles];
    let finalWarehouses = [...state.warehouses];
    for (const singleArticleWarehouse of payload._id.articlesWarehousesSave) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
          if (singleArticle.CANT_ALMACENES) {
            let found = false;
            for (const [
              singleWarehouseQuantityIndex,
              singleWarehouseQuantity,
            ] of singleArticle.CANT_ALMACENES.entries()) {
              if (singleWarehouseQuantity._id === singleArticleWarehouse._id) {
                found = true;
                if (singleArticleWarehouse.CANTIDAD) {
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD += Number(singleArticleWarehouse.CANTIDAD);
                }

                if (singleArticleWarehouse.CANTIDAD_VIRTUAL) {
                  finalArticles[singleArticleIndex].CANT_ALMACENES[
                    singleWarehouseQuantityIndex
                  ].CANTIDAD_VIRTUAL += Number(
                    singleArticleWarehouse.CANTIDAD_VIRTUAL
                  );
                }
              }
            }
            if (found === false) {
              finalArticles[singleArticleIndex].CANT_ALMACENES.push({
                ...singleArticleWarehouse,
              });
            }
          } else {
            finalArticles[singleArticleIndex].CANT_ALMACENES = [
              { ...singleArticleWarehouse },
            ];
          }
          break;
        }
      }
    }

    let allTransfersDone = [...payload._id.transfersDone];
    for (const [
      singleTrasferIndex,
      singleTransfer,
    ] of allTransfersDone.entries()) {
      for (const [
        singleWarehouseindex,
        singleWarehouse,
      ] of state.warehouses.entries()) {
        if (
          finalWarehouses[singleWarehouseindex]._id === singleTransfer.CLAVE_ALM
        ) {
          for (const [
            sigleWarehouseTransferIndex,
            singleWarehouseTransfer,
          ] of finalWarehouses[singleWarehouseindex].CLAVE_TRANS.entries()) {
            if (singleWarehouseTransfer._id === singleTransfer._id) {
              finalWarehouses[singleWarehouseindex].CLAVE_TRANS[
                sigleWarehouseTransferIndex
              ].STATUS_TRA = "C";
            }
          }
        }
      }
    }

    state.warehouses = finalWarehouses;
    state.article = finalArticles;
  },
  SET_TRANSFERS: (state, payload) => {
    state.warehousesTransfers = payload.warehousesTransfers;
  },
  CANCEL_TRANSFER: (state, payload) => {
    let finalArticles = [...state.articles];
    let finalWarehouses = [...state.warehouses];

    for (const singleArticleWarehouse of payload._id.articlesWarehousesSave) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
          let found = false;
          for (const [
            singleWarehouseQuantityIndex,
            singleWarehouseQuantity,
          ] of singleArticle.CANT_ALMACENES.entries()) {
            if (singleWarehouseQuantity._id === singleArticleWarehouse._id) {
              found = true;
              finalArticles[singleArticleIndex].CANT_ALMACENES[
                singleWarehouseQuantityIndex
              ].CANTIDAD += Number(singleArticleWarehouse.CANTIDAD);
            }
          }
          if (found === false) {
            finalArticles[singleArticleIndex].CANT_ALMACENES.push({
              ...singleArticleWarehouse,
            });
          }

          break;
        }
      }
    }

    let allTransfersDone = [...payload._id.transfersDone];

    for (const [
      singleTrasferIndex,
      singleTransfer,
    ] of allTransfersDone.entries()) {
      for (const [
        singleWarehouseindex,
        singleWarehouse,
      ] of state.warehouses.entries()) {
        if (
          finalWarehouses[singleWarehouseindex]._id === singleTransfer.CLAVE_ALM
        ) {
          for (const [
            sigleWarehouseTransferIndex,
            singleWarehouseTransfer,
          ] of finalWarehouses[singleWarehouseindex].CLAVE_TRANS.entries()) {
            if (singleWarehouseTransfer._id === singleTransfer._id) {
              finalWarehouses[singleWarehouseindex].CLAVE_TRANS[
                sigleWarehouseTransferIndex
              ].STATUS_TRA = "CA";
            }
          }
        }
      }
    }

    state.warehouses = finalWarehouses;
    state.article = finalArticles;
  },
  RECEIVE_MATERIAL: (state, payload) => {
    let finalArticles = [...state.articles];
    let finalWarehouses = [...state.warehouses];
    let finalPurchase = [...state.purchaseOrders];
    let finalProviders = [...state.providers];
    let finalMaterialReceptions = [...state.materialReceptions];

    for (const singleArticleWarehouse of payload._id.articlesWarehousesSave) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of finalArticles.entries()) {
        if (singleArticleWarehouse.CLAVE_ART === singleArticle._id) {
          if (singleArticle.CANT_ALMACENES) {
            let found = false;
            for (const [
              singleWarehouseQuantityIndex,
              singleWarehouseQuantity,
            ] of singleArticle.CANT_ALMACENES.entries()) {
              if (singleWarehouseQuantity._id === singleArticleWarehouse._id) {
                found = true;
                finalArticles[singleArticleIndex].CANT_ALMACENES[
                  singleWarehouseQuantityIndex
                ].CANTIDAD += Number(singleArticleWarehouse.CANTIDAD);
                finalArticles[singleArticleIndex].CANT_ALMACENES[
                  singleWarehouseQuantityIndex
                ].CANTIDAD_VIRTUAL += Number(singleArticleWarehouse.CANTIDAD);
              }
            }
            if (found === false) {
              finalArticles[singleArticleIndex].CANT_ALMACENES.push({
                ...singleArticleWarehouse,
              });
            }
          } else {
            finalArticles[singleArticleIndex].CANT_ALMACENES = [
              { ...singleArticleWarehouse },
            ];
          }
          break;
        }
      }
    }

    let allTransfersDone = [...payload._id.transfersDone];
    for (const [
      singleTrasferIndex,
      singleTransfer,
    ] of allTransfersDone.entries()) {
      for (const [
        singleWarehouseindex,
        singleWarehouse,
      ] of state.warehouses.entries()) {
        for (let currentWarehouse of state.warehouses) {
          if (currentWarehouse._id === singleTransfer.ORIGEN) {
            allTransfersDone[singleTrasferIndex].ORIGEN = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentWarehouse of state.warehouses) {
          if (currentWarehouse._id === singleTransfer.DESTINO) {
            allTransfersDone[singleTrasferIndex].DESTINO = {
              _id: currentWarehouse._id,
              CLAVE_ALMACEN: currentWarehouse.CLAVE_ALMACEN,
              NOMBRE_ALM: currentWarehouse.NOMBRE_ALM,
              DESCRIP_BREV: currentWarehouse.DESCRIP_BREV,
              IPO: currentWarehouse.IPO,
              UBICACION: currentWarehouse.UBICACION,
              CP_ALM: currentWarehouse.CP_ALM,
            };
            break;
          }
        }

        for (let currentArticle of state.articles) {
          if (currentArticle._id === singleTransfer.CLAVE_ART) {
            allTransfersDone[singleTrasferIndex].CLAVE_ART = {
              _id: currentArticle._id,
              CLAVE_ART: currentArticle.CLAVE_ART,
              DESCRIPCION: currentArticle.DESCRIPCION,
            };
            break;
          }
        }

        if (
          finalWarehouses[singleWarehouseindex]._id === singleTransfer.CLAVE_ALM
        ) {
          if (finalWarehouses[singleWarehouseindex].CLAVE_TRANS) {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS.push(
              allTransfersDone[singleTrasferIndex]
            );
          } else {
            finalWarehouses[singleWarehouseindex].CLAVE_TRANS = [
              allTransfersDone[singleTrasferIndex],
            ];
          }
        }
      }
    }

    for (const [
      singlePurchaseOrderIndex,
      singlePurchaseOrder,
    ] of finalPurchase.entries()) {
      if (singlePurchaseOrder._id === payload._id.purchaseOrderId) {
        finalPurchase[singlePurchaseOrderIndex].STATUS_PED =
          payload._id.purchaseOrderStatus;

        finalPurchase[singlePurchaseOrderIndex].RESTA -=
          payload._id.expense.TOTAL_GTO;

        if (finalPurchase[singlePurchaseOrderIndex].CUENTAS_PAG) {
          finalPurchase[singlePurchaseOrderIndex].CUENTAS_PAG.push(
            payload._id.expense
          );
        } else {
          finalPurchase[singlePurchaseOrderIndex].CUENTAS_PAG = [
            payload._id.expense,
          ];
        }

        for (const [singleItemIndex, singleItem] of finalPurchase[
          singlePurchaseOrderIndex
        ].PEDIDO_DET.entries()) {
          for (const [
            singleItemPayloadIndex,
            singleItemPayload,
          ] of payload.items.entries()) {
            if (singleItemPayload.article === singleItem.CLAVE_ART._id) {
              finalPurchase[singlePurchaseOrderIndex].PEDIDO_DET[
                singleItemIndex
              ].CANTIDAD_RE += Number(singleItemPayload.newReception);
              finalPurchase[singlePurchaseOrderIndex].PEDIDO_DET[
                singleItemIndex
              ].CANTIDAD_PR -= Number(singleItemPayload.newReception);
            }
          }
        }
      }
    }

    // update provider balance
    for (const [
      singleProviderIndex,
      singleProvider,
    ] of finalProviders.entries()) {
      if (singleProvider._id === payload._id.expense.CLAVE_PRV) {
        finalProviders[singleProviderIndex].BALANCE_FIN =
          payload._id.newBalance.SALDO_F;
        if (!finalProviders[singleProviderIndex].BALANCES) {
          finalProviders[singleProviderIndex].BALANCES = [
            payload._id.newBalance,
          ];
        } else {
          finalProviders[singleProviderIndex].BALANCES.push(
            payload._id.newBalance
          );
        }
      }
    }

    if (payload._id.materialReception) {
      finalMaterialReceptions.push(payload._id.materialReception);
    }

    let newExpenseSave = { ...payload._id.expense };
    newExpenseSave.GASTO_DET = [...payload._id.expense.GASTO_DET];
    payload.context.rootState.Treasury.expenses.push(newExpenseSave);
    payload.context.rootState.Treasury.lastExpenseNumber += 1;
    state.warehouses = finalWarehouses;
    state.lastWarehouseTransferNumber = payload._id.lastWarehouseTransferNumber;
    state.providers = finalProviders;
    state.articles = finalArticles;
    state.purchaseOrders = finalPurchase;
    state.materialReceptions = finalMaterialReceptions;
  },
  RECEIVE_ALL_MATERIAL: (state, payload) => {
    let finalPurchaseOrders = [...state.purchaseOrders];
    for (const [
      purhcaseOrderIndex,
      purchaseOrder,
    ] of finalPurchaseOrders.entries()) {
      if (purchaseOrder._id === payload._id.purchaseOrderId) {
        finalPurchaseOrders[purhcaseOrderIndex].STATUS_PED = payload._id.type;
        break;
      }
    }

    state.purchaseOrders = finalPurchaseOrders;
  },
  SET_SAT_ARTICLES_DIVISION: (state, payload) => {
    state.satArticlesDivision = payload;
  },
  SET_PROVIDER_ARTICLE_COST: (state, payload) => {
    const currentArticles = state.articles;

    for (const [
      singleArticleIndex,
      singleArticle,
    ] of currentArticles.entries()) {
      if (singleArticle._id === payload.article) {
        currentArticles[singleArticleIndex].COSTO_MIN_VTA = payload.cost;
      }
    }

    state.articles = currentArticles;
  },
  DELETE_PROVIDER_ARTICLE_COST: (state, payload) => {
    const currentArticles = state.articles;

    for (const [
      singleArticleIndex,
      singleArticle,
    ] of currentArticles.entries()) {
      if (singleArticle._id === payload._id.article) {
        for (const [
          singleProviderPriceIndex,
          singleProviderPrice,
        ] of singleArticle.PRECIOS_PROV.entries()) {
          if (singleProviderPrice._id === payload._id.providerPrice) {
            currentArticles[singleArticleIndex].PRECIOS_PROV.splice(
              singleProviderPriceIndex,
              1
            );
          }
        }
      }
    }

    state.articles = currentArticles;
  },
  EDIT_WAREHOUSE_MIN_MAX: (state, payload) => {
    const currentArticles = state.articles;

    // Move trough the articles to find the one that was edited
    for (const [
      singleArticleIndex,
      singleArticle,
    ] of currentArticles.entries()) {
      // If _id id the same as the one edited
      if (singleArticle._id === payload._id.article) {
        if (payload._id.found) {
          for (const [
            singleItemIndex,
            singleItem,
          ] of singleArticle.CANT_ALMACENES.entries()) {
            // Search the warehouse articles
            if (singleItem.CLAVE_ALMACEN === payload._id.warehouse) {
              // Change needed values

              currentArticles[singleArticleIndex].CANT_ALMACENES[
                singleItemIndex
              ].MINIMO = payload._id.newValues.MINIMO;

              currentArticles[singleArticleIndex].CANT_ALMACENES[
                singleItemIndex
              ].MAXIMO = payload._id.newValues.MAXIMO;
            }
          }
        } else {
          // Push new object
          currentArticles[singleArticleIndex].CANT_ALMACENES.push(
            payload._id.newWarehouseArticles
          );
        }
      }
    }

    state.articles = currentArticles;
  },
  SET_MATERIAL_RECEPTIONS: (state, payload) => {
    state.materialReceptions = payload;
  },
};

export default mutations;
