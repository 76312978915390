import {
  saveAction,
  getAction,
  genericActionNoMutation,
} from "@/utils/store-fns";
import { serverUrl } from "@/utils/env-variables-fns";

export default {
  CREATE_CONVERSATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/analytics/create-conversation`,
        "CREATE_CONVERSATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },

  HANDLE_ANALYTICS_MESSAGE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/analytics/handle-analytics-message`,
        "HANDLE_ANALYTICS_MESSAGE"
      ).then((response) => {
        resolve(response);
      });
    });
  },

  GET_METRICS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/analytics/get-metrics`,
        "SET_METRICS",
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },

  GET_CONVERSATION: async (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/analytics/get-conversation`,
        "SET_CONVERSATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
};
