import {
  saveAction,
  getAction,
  saveActionComplete,
  saveActionCompleteResponseContext,
  genericActionNoMutation,
  genericActionNoMutationNoLoading,
  genericActionNoLoading,
} from "../../../utils/store-fns";
import { serverUrl } from "../../../utils/env-variables-fns";

let actions = {
  /**
   *
   * @desc Saves a new warehous in the database and saves given warehouse in the current sesion of the user
   */
  SAVEWAREHOUSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/save-warehouse`,
        "SAVE_WAREHOUSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all warehouses from the database
   */
  GETWAREHOUSES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-warehouses`,
        "SET_WAREHOUSES",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all warehouses from the database
   */
  GETWAREHOUSESNOTRANSFERS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-warehouses-no-transfers`,
        "SET_WAREHOUSES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Activates a warehouses
   */
  ACTIVATEWAREHOUSES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/activate-warehouse`,
        "ACTIVATE_WAREHOUSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Disables a warehouses
   */
  DISABLEWAREHOUSES: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/disable-warehouse`,
        "DISABLE_WAREHOUSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a warehouse in the database
   */
  EDITWAREHOUSE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/edit-warehouse`,
        "EDIT_WAREHOUSE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new article in the database and saves given article in the current sesion of the user
   */
  SAVEARTICLE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/save-article`,
        "SAVE_ARTICLE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all articles from the database
   */
  GETARTICLES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-articles`,
        "SET_ARTICLES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets articles paginated from the database
   */
  GETARTICLESPAGINATED: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-articles-paginated`,
        "SET_ARTICLES_PAGINATED",
        {
          limit: payload.limit,
          skip: payload.skip,
          keySearchInput: payload.keySearchInput,
          descriptionKeyInput: payload.descriptionKeyInput,
          saleActive: payload.saleActive,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Searches fos specific articles from the database
   */
  SEARCHARTICLES: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/search-articles`,
        "SET_ARTICLES_PAGINATED",
        {
          limit: payload.limit,
          skip: payload.skip,
          search: payload.search,
          saleActive: payload.saleActive,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an article in the database
   */
  EDITARTICLE: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/edit-article`,
        "EDIT_ARTICLE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new provider in the database and saves given provider in the current sesion of the user
   */
  SAVEPROVIDER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/save-provider`,
        "SAVE_PROVIDER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all providers from the database
   */
  GETPROVIDERS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-providers`,
        "SET_PROVIDERS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a warehouse in the database
   */
  EDITPROVIDER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/edit-provider`,
        "EDIT_PROVIDER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Save or update new providers prices
   */
  SAVEPROVIDERSPRICES: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/save-providers-prices`,
        "SET_PROVIDERS_PRICES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all the payment periods from the database
   */
  GETPAYMENTPERIODS: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-payment-periods`,
        "SET_PAYMENT_PERIODS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Save a new payment period
   */
  SAVEPAYMENTPERIOD: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/save-payment-period`,
        "ADD_PAYMENT_PERIODS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets all the types deliveries from the database
   */
  GETTYPEDELIVERIES: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-types-deliveries`,
        "SET_TYPES_DELIVERY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Save a new type delivery
   */
  SAVETYPEDELIVERY: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/save-type-delivery`,
        "ADD_TYPES_DELIVERY"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets purchase orders from the database
   */
  GETPURCHASEORDERS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-purchase-orders`,
        "SET_PURCHASE_ORDERS",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
          warehouses: payload.warehouses,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets purchase orders from the database
   */
  GETREQUESTORDERS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-request-orders`,
        "SET_REQUEST_ORDERS",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
          warehouses: payload.warehouses,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Save a new purchase order
   */
  SAVEREQUESTORDER: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/save-purchase-order`,
        "ADD_REQUEST_ORDERS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Cancelss a purchase order
   */
  CANCELPURCHASEORDER: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/cancel-purchase-order`,
        "CANCEL_PURCHASE_ORDER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a request order in the database
   */
  EDITREQUESTORDER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/edit-request-order`,
        "EDIT_REQUEST_ORDER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Deletes a request order in the database
   */
  DELETEREQUESTORDERS: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/delete-quotes`,
        "DELETE_REQUEST_ORDER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CHANGEPROVIDERPRICE: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/set-provider-price`,
        "SET_PROVIDER_PRICE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CONFIRMREQUESTORDER: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/confirm-request-order`,
        "CONFIRM_REQUEST_ORDERS"
      ).then((response) => {
        context.dispatch("GETCOMISSIONS", { type: 0 });
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets last purchase order number from the database
   */
  GETLASTPURCHASEORDERNUMBER: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-last-purchase-order-number`,
        "SET_LAST_PURCHASE_ORDER_NUMBER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Add one to the print or send counter in a purchase order or requestOrder
   */
  PRINTORSENDPURCHASEORDER: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/print-or-send-purchase-order`,
        "PRINT_OR_SEND_PURCHASE_ORDER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Saves the costs of a specific provider
   */
  SAVEPROVIDERCOSTS: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/save-provider-costs`,
        "SAVE_PROVIDERS_COSTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Saves the costs of a specific provider
   */
  SAVEPROVIDERDISCOUNTS: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/save-provider-discounts`,
        "SAVE_PROVIDERS_DISCOUNTS"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Generates a smart purchase order
   */
  GENERATESMARTORDER: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/purchases/generate-smart-order`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Saves a new article presentation in the database and saves given presentation in the current sesion of the user
   */
  SAVEARTICLEPRESENTATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/save-article-presentation`,
        "SAVE_ARTICLE_PRESENTATION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits an article presentation in the database
   */
  EDITARTICLEPRESENTATION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/edit-article-presentation`,
        "EDIT_ARTICLE"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETLASTWAREHOUSETRANSFERNUMBER: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-last-warehouse-transfer-number`,
        "SET_LAST_WAREHOUSE_TRANSFER_NUMBER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  TRANSFERARTICLES: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponseContext(
        context,
        payload,
        `${serverUrl}/purchases/transfer-articles`,
        "TRANSFER_ARTICLES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  ADDARTICLEQUANTITY: (context, payload) => {
    return new Promise((resolve) => {
      saveActionCompleteResponseContext(
        context,
        payload,
        `${serverUrl}/purchases/add-articles-quantity`,
        "TRANSFER_ARTICLES"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CONFIRMTRANSACTION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/confirm-transaction`,
        "CONFIRM_TRANSFER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  CANCELTRANSACTION: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/cancel-transaction`,
        "CANCEL_TRANSFER"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  RECEIVEMATERIAL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/receive-material`,
        "RECEIVE_MATERIAL"
      ).then((response) => {
        context.dispatch("GETCOMISSIONS", { type: 0 });
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Gets material receptions from the database
   */
  GETMATERIALRECEPTIONS: (context, payload) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-material-receptions`,
        "SET_MATERIAL_RECEPTIONS",
        {
          startDate: payload.startDate,
          endDate: payload.endDate,
          warehouses: payload.warehouses,
        }
      ).then((response) => {
        resolve(response);
      });
    });
  },
  RECEIVEALLMATERIAL: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/receive-all-material`,
        "RECEIVE_ALL_MATERIAL"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @des Gets the sat articles divisions from the database
   */
  GETSATARTICLESDIVISION: (context) => {
    return new Promise((resolve) => {
      getAction(
        context,
        `${serverUrl}/purchases/get-sat-articles-division`,
        "SET_SAT_ARTICLES_DIVISION"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @des Gets the sat articles groups from the database
   */
  GETSATARTICLESGROUPS: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutationNoLoading(
        context,
        payload,
        `${serverUrl}/purchases/get-sat-articles-groups`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @des Gets the sat articles from the database
   */
  GETSATARTICLES: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutationNoLoading(
        context,
        payload,
        `${serverUrl}/purchases/get-sat-articles`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  SAVEPROVIDERARTICLECOST: (context, payload) => {
    return new Promise((resolve) => {
      saveActionComplete(
        context,
        payload,
        `${serverUrl}/purchases/save-provider-article-cost`,
        "SET_PROVIDER_ARTICLE_COST"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  DELETEPROVIDERARTICLECOST: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/delete-provider-price`,
        "DELETE_PROVIDER_ARTICLE_COST"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Edits a warehouse article minimum and maximum in the database
   */
  EDITWAREHOUSEMINMAX: (context, payload) => {
    return new Promise((resolve) => {
      saveAction(
        context,
        payload,
        `${serverUrl}/purchases/edit-warehouse-min-max`,
        "EDIT_WAREHOUSE_MIN_MAX"
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Generates a smart purchase order with min and max
   */
  GENERATESMARTORDERMINMAX: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/purchases/generate-smart-purchase-order-min-max`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   * @desc Resets sales inventory
   */
  RESETSALESINVENVTORY: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoLoading(
        context,
        payload,
        `${serverUrl}/purchases/reset-sales-inventory`
      ).then((response) => {
        resolve(response);
      });
    });
  },
  TRAINMODELWITHSALES: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/purchases/train-model-with-sales`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Makes a prediction
   */
  MAKEPREDICTION: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/purchases/make-prediction`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  /**
   *
   * @desc Makes a prediction
   */
  MAKEPSTATISTICSREDICTION: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/purchases/make-statistics-prediction`,
        payload
      ).then((response) => {
        resolve(response);
      });
    });
  },
  GETARTICLESNOMUTATION: (context, payload) => {
    return new Promise((resolve) => {
      genericActionNoMutation(
        context,
        payload,
        `${serverUrl}/purchases/get-articles`
      ).then((response) => {
        resolve(response);
      });
    });
  },
};

export default actions;
